<script>
  import { createEventDispatcher } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { bounceOut } from 'svelte/easing';
  export let title, text, colour;
  const dispatch = createEventDispatcher();

  function dismiss() {
    text = '';
  }
</script>

<style>
  div {
    position: fixed;
    top: 50px;
    left: 50%;
    width:400px;
    border: solid black thin;
    z-index:1000;
    transform: translateX(-50%);
  }

  button {
    float: right;
    border: none;
    margin: 0;
    width: auto;
    padding: 0 5px 40px 40px;
  }

  .red {
    background-color: #FAA;
    border-color: #B00;
    color: #B00;
  }

  .green {
    background-color: #AFA;
    border: #0B0;
    color: #0B0;
  }

  h3, p {
    margin: 5px;
  }
</style>

{#if text}
  <div
    class={colour}
    in:fly="{{ y: -100, duration: 500, easing: bounceOut }}"
    out:fade
  >
    <button class={colour} on:click|preventDefault={dismiss}>X</button>
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
{/if}